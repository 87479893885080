<template>
  <div>
    <a-row>
      <a-col :span="isDesktop ? 18 : 24" style="padding-right: 10px">
        <a-col :span="24">
          <HomeFilter />
        </a-col>
        <a-col :span="24"> <MyProcesses /> </a-col
      ></a-col>
      <a-col :span="6" style="padding-left: 10px" v-if="isDesktop">
        <a-col :span="24">
          <NewProcessButton />
        </a-col>
        <a-col :span="24"><RecentProcess /> </a-col
      ></a-col>
    </a-row>
    <a-modal
      v-model:visible="showTermsModal"
      title="Termos e Condições"
      width="800px"
      :footer="null"
      :maskClosable="false"
  :closable="false"
    >
      <div style="display: flex; flex-direction: column;">
        <div style="display: flex; width: 100%; height: 300px; margin-bottom: 20px;">
          <iframe
            src="https://app.travelfybrasil.com/terms" 
            width="100%"
            height="100%"
            frameborder="0"
            style="border: none;"
          ></iframe>
        </div>
        <div style="margin-bottom: 20px;">
          <label>
            <input
              type="checkbox"
              v-model="isTermsAccepted"
            />
            Eu li e aceito os termos e condições.
          </label>
        </div>
        <a-button
          type="primary"
          block
          size="large"
          @click="handleAccept"
          :loading="isLoading"
          style="background: linear-gradient(270deg,
          rgba(63, 161, 255, 1) 0%,
          rgba(103, 180, 255, 1) 77%,
          rgba(144, 200, 255, 1) 100%);
          border-color: rgba(144, 200, 255, 1);
          font-weight: bold;
          font-size: 16px;"
        >
          Aceitar
        </a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, computed, createVNode, ref, onMounted } from "vue";
import NewProcessButton from "@/components/NewProcessButton/NewProcessButton";
import RecentProcess from "@/components/RecentProcess/RecentProcess";
import MyProcesses from "@/components/MyProcesses/MyProcesses";
import HomeFilter from "@/components/HomeFilter/HomeFilter";
import { Modal } from "ant-design-vue";
import { useStore } from "vuex";
import { isDesktop } from "@/utils/isAgent";
export default defineComponent({
  components: {
    RecentProcess,
    MyProcesses,
    HomeFilter,
    NewProcessButton,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const showTermsModal = ref(false);
    const isTermsAccepted = ref(false);
    const isLoading = ref(false);

    const handleAccept = async () => {
      if (!isTermsAccepted.value) {
        Modal.warning({
          title: "Atenção",
          content: "É necessário aceitar os termos para continuar.",
        });
        return;
      }
      isLoading.value = true;
      try {
        await store.dispatch("user/alterTermsUser", user.value.id);
        showTermsModal.value = false;
        showWelcomeModal();
      } catch (error) {
        console.error("Erro ao aceitar os termos:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const showWelcomeModal = async () => {
      const response = await store.dispatch("user/verifyWelcomeUser", user.value.id);
      if (!response.status) {
        Modal.success({
          title: "🎉 Bem vindo!",
          content: createVNode("div", {}, [
            createVNode("iframe", {
              src: "https://player.vimeo.com/video/1018685407",
              width: "100%",
              height: "300px",
              frameborder: "0",
              allow: "autoplay; fullscreen; picture-in-picture",
              style: "margin-top: 20px; border-radius: 10px;",
              allowfullscreen: true,
            }),
          ]),
          width: 600,
          style: { top: "100px" },
          onOk: async () => {
            await store.dispatch("user/alterWelcomeUser", user.value.id);
          },
        });
      }
    };

    const checkTerms = async () => {
      const response = await store.dispatch("user/verifyTermsUser", user.value.id);
      if (!response.status) {
        showTermsModal.value = true;
      } else {
        showWelcomeModal();
      }
    };

    onMounted(() => {
      checkTerms();
    });

    return {
      isDesktop,
      showTermsModal,
      isTermsAccepted,
      isLoading,
      handleAccept,
    };
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
